import { render, staticRenderFns } from "./journalSource.vue?vue&type=template&id=138ba015&scoped=true&"
import script from "./journalSource.vue?vue&type=script&lang=js&"
export * from "./journalSource.vue?vue&type=script&lang=js&"
import style0 from "./journalSource.vue?vue&type=style&index=0&id=138ba015&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138ba015",
  null
  
)

export default component.exports