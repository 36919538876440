<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
			<input type="hidden" id="authorOrcid" value="${authorOrcid}" />
		
			<div>
				<!--            {{// authorOrcid}}-->
			</div>
			<div class="container-1200" style="padding-top: 20px;">
				
				<div class="layui-fluid" style="margin-top: 40px; display: none">
					<div class="layui-row">
						<div class="layui-col-md10">
							<input type="text" id="articleTitle" autocomplete="off" placeholder="Article Title"
								class="layui-input" value="${articleTitle}">
						</div>
						<div class="layui-col-md2">
							<div class="layui-btn-container">
								<button type="button" class="layui-btn layui-btn-fluid" id="search">Search</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">
						Search Results: <span class="loading">
							<img v-if="loadingShow" src="../../assets/images/loading.gif"
								style="width: 45px;" />
								<span v-else>{{dataCount}} {{paramMap.articleTitleAZ}}</span></span>
					</strong></p>
			</div>
			<div class="layui-fluid">
				<div class="layui-row layui-col-space30" style="margin-top: 40px;">
					<div class="layui-col-md3">
						<div style="margin-top: 20px;" class="layui-hide-xs">
							<h3><strong>PUBLICATION</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<form class="layui-form" action="" lay-filter="publisherP">
									<!--								publiction多选框-->
									<el-checkbox-group v-model="publisherP" @change="checkboxCli" text-color="red">
										<ul class="search sourceTitle" style="padding: 20px;">
											<li v-for="(item,index) of publication" :key="index">
												<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
											</li>
										</ul>
									</el-checkbox-group>
								</form>
							</div>
						</div>
						<div style="margin-top: 20px;line-height: 30px;" class="layui-hide-xs">
							<h3><strong>YEAR OF PUBLICATION</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<form class="layui-form" action="" lay-filter="publicationYear">
									<el-checkbox-group v-model="publicationYear" @change="checkboxCli" text-color="red">
										<ul class="search sourceTitle" style="padding: 20px;">
											<li v-for="(item,index) of yearPublication" :key="index">
												<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
											</li>
										</ul>
									</el-checkbox-group>
								</form>
							</div>
						</div>
						<div style="margin-top: 20px;line-height: 30px;" class="layui-hide-xs">
							<h3><strong>DOCUMENT TYPES</strong></h3>
							<div class="layui-panel" style="margin-top: 20px;">
								<el-checkbox-group v-model="documentType" @change="checkboxCli" text-color="red">
									<ul class="search sourceTitle" style="padding: 20px;">
										<li v-for="(item,index) of docType" :key="index">
											<el-checkbox :label="item.a">{{item.a}}({{item.b}},{{item.c}})</el-checkbox>
										</li>
									</ul>
								</el-checkbox-group>
							</div>
						</div>
					</div>
					<div class="layui-col-md9">
						<div>
							<h3><strong>Article or Review Article</strong>
								<p class="right">Results: <span class="currPage">1</span> - 10 of <span
										class="countNum">{{dataCount}}</span><span class="loading" v-if="loadingShow"><img
											src="../../assets/images/loading.gif" style="width: 45px;" /></span>
								</p>
							</h3>
							<div class="content">
								<div class="layui-panel" style="margin-top: 20px;padding: 20px;display: flex"
									v-for="(item,index) of journalList" :key="index">
									<div
										style="margin-top: 20px; display: flex;flex-direction: column;align-items: flex-end;">
										<div style="display: flex; align-items: flex-start;">
											<span>{{index+1}}.</span><input style="margin-left: 5px;margin-top: 1px"
												type="checkbox" name="AAA" title="" @click="">
										</div>
										<div style="margin-top: 5px"><i
												:class="item.openAccessDesignations == null || item.openAccessDesignations.indexOf('hybrid')!=-1 ? 'bi bi-lock-fill' : 'bi bi-unlock-fill'"></i>
										</div>
									</div>
									<div style="margin-left: 10px">
										<p class="two float-hand-color"
											style="margin-top: 20px;font-size: 16px;height: 36px;" data-id="16">
											<strong
												@click="detailsBtn(item.id,item.articleTitle)">{{item.articleTitle}}</strong>
										</p>
										<p style="font-size: 16px;margin-top: 20px;color: #535353;">
											<span>{{item.authorFullNames}}</span>
										</p>
										<p style="font-size: 16px;margin-top: 20px;color: #191919;">
											<span>{{item.sourceTitle}}.v.{{item.volumeV}},n.issueI,pp.{{item.startPage}}-{{item.endPage}},{{item.publicationYear}},</span><span>57,</span><span>1,</span>
										</p>
										<p style="font-size: 16px;margin-top: 20px;color: #191919;">Contrimetric:<span
												style="color: #1da6b8">cf1</span>,<span style="color: #1da6b8">cf2</span>
										</p>
									</div>
								</div>
							</div>
		
							<div id="demo3" style="text-align: center;margin-top: 40px;">
								<!--							分页-->
								<el-pagination background layout="prev, pager, next" :total="dataCount"
									v-model:current-page="paramMap.page" @size-change="" @current-change="pageCli" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	
</template>

<script>
	import $http from "@/request/http";

	export default {
		name: "journalSource",
		data() {
			return {
				dataCount: 0,
				sourceTitle: "",
				journalList: [],
				paramMap: {
					articleTitle: "",
					articleTitleAZ: "",
					authorFullNames: "",
					authorOrcid: "",
					articleTitle: "",
					documentType: "{}",
					page: 1,
					publicationYear: "{}",
					publisherP: "{}"
				},
				jourList: [],
				journalAofZ: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R",
					"S", "T", "U", "V", "W", "X", "Y", "Z"
				],
				publication: [{
					a: "WILEY",
					b: "126",
					c: "369"
				}, {
					a: "ELSEVIER",
					b: "110",
					c: "288"
				}, {
					a: "ELSEVIER SCI LTD",
					b: "95",
					c: "904"
				}, {
					a: "PERGAMON-ELSEVIER SCIENCE LTD",
					b: "94",
					c: "090"
				}, {
					a: "ELSEVIER SCIENCE BV",
					b: "85",
					c: "772"
				}, {
					a: "SPRINGER",
					b: "85",
					c: "706"
				}, {
					a: "AMER CHEMICAL SOC",
					b: "74",
					c: "579"
				}, {
					a: "MDPI",
					b: "73",
					c: "382"
				}, {
					a: "IEEE-INST ELECTRICAL ELECTRONICS ENGINEERS INC",
					b: "70",
					c: "286"
				}, {
					a: "ROYAL SOC CHEMISTRY",
					b: "41",
					c: "706"
				}],
				publisherP: [],
				yearPublication: [{
					a: "2022",
					b: "40",
					c: "963"
				}, {
					a: "2021",
					b: "54",
					c: "206"
				}, {
					a: "2020",
					b: "260",
					c: "285"
				}, {
					a: "2019",
					b: "344",
					c: "537"
				}, {
					a: "2018",
					b: "336",
					c: "425"
				}, {
					a: "2017",
					b: "320",
					c: "777"
				}, {
					a: "2016",
					b: "290",
					c: "507"
				}],
				publicationYear: [],
				docType: [{
					a: "Article",
					b: "143",
					c: "7672"
				}, {
					a: "Review",
					b: "18",
					c: "3201"
				}, {
					a: "Article; Proceedings Paper",
					b: "2",
					c: "3969"
				}],
				documentType: [],
				loadingShow: true
			}
		},
		methods: {
			async queryJournalList() {
				this.loadingShow = true;
				$http.post("getJournalList", this.paramMap, true).then(res => {
					this.jourList = [];
					this.jourList = res.journalList;
					this.journalList = JSON.parse(JSON.stringify(res.data.journalList));
					this.loadingShow = false
				}).catch(err => {
					console.log(err);
				});
			},
			async queryJournalCount() {
				$http.post("countJournal", this.paramMap).then(res => {
					this.dataCount = res.data.count

				}).catch(err => {
					console.log(err);
				})
			},
			pageCli() {
				this.queryJournalList()
				this.queryJournalCount()
			},
			detailsBtn(id, title) {
				this.$router.push({
					path: "/journalDetails",
					query: {
						id: id
					}
				});
			},
			checkboxCli() {
				this.paramMap.publisherP = "{"
				this.publisherP.forEach((res, index) => {
					if (index == this.publisherP.length - 1) {
						this.paramMap.publisherP += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.publisherP += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.publisherP += "}"

				this.paramMap.publicationYear = "{"
				this.publicationYear.forEach((res, index) => {
					if (index == this.publicationYear.length - 1) {
						this.paramMap.publicationYear += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.publicationYear += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.publicationYear += "}"


				this.paramMap.documentType = "{"
				this.documentType.forEach((res, index) => {
					if (index == this.documentType.length - 1) {
						this.paramMap.documentType += '"' + res + '"' + ":" + '"on"';
					} else {
						this.paramMap.documentType += '"' + res + '"' + ":" + '"on",';
					}
				})
				this.paramMap.documentType += "}"

				console.log(this.paramMap.documentType);


				this.queryJournalList()
				this.queryJournalCount()
			},
		},
		created() {
			this.paramMap.articleTitleAZ = this.$route.query.name
			this.paramMap.articleTitle = this.$route.query.articleTitle
			this.queryJournalList()
			this.queryJournalCount()
		}
	}
</script>

<style scoped>
	::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
		color: #1da6b8;
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #1da6b8;
		border-color: #1da6b8;
	}

	::v-deep .el-checkbox {
		padding-top: 4px;
		line-height: 32px;
	}
</style>